.main {
  max-width: 800px;
  margin: auto;
  padding: 15px;

  section > div > div > label {
    font-weight: bold;
    display: block;
    padding: 5px 5px 0;
  }
  input[type="text"].locationLabelInput {
    margin-bottom: 10px;
  }

  input[type="text"],
  input[type="url"],
  textarea {
    width: 90%;
    padding: 5px;
    margin: 5px;
    font-family: inherit;
    font-size: inherit;
  }
  .quillContainer {
    margin: 5px;
    width: calc(90% + 12px);
    font-family: inherit;
    font-size: inherit;
  }
  input[type="submit"] {
    text-align: right;
    color: #fff;
    background-color: #449d44;
    border: none;
    color: white;
    padding: 10px;
    float: right;
    margin-bottom: 20px;
    cursor: pointer;
    &:hover {
      background-color: #299b29;
    }
  }
}

header {
  text-align: right;
}

section {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #000;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

section .heading {
  border-color: #000000;
  background-color: #9c9c9c;
  color: #ffffff !important;
  font-size: 16px;
  font-weight: bolder;
  padding: 10px 15px;
  border-bottom: 1px solid #000;
}
section .body {
  padding: 15px;
  p {
    margin: 0;
  }
}

.logo {
  text-align: right;
  margin-top: 0px;
  height: 50px;
  margin-bottom: 20px;
}

.submitSuccess {
  background-color: palegreen;
  color: black;
  padding: 10px;
  margin: 10px;
}

.eventLocationInfoBox {
  background-color: lightblue;
  color: black;
  padding: 10px;
  margin: 10px 0 !important;
  font-style: italic;
  max-width: 700px;
}

.mediaWidgetWrapper > * {
  overflow-y: hidden;
  label {
    display: block;
    margin: 8px 0 4px;
  }
  input {
    border: none;
    padding: 8px;
    font-size: 16px;
  }
}

.toggleLocationSelectionTypeButton {
  padding: 5px 10px;
  margin: 5px 0 0 5px;
  background-color: var(--FF-COLOR-SECONDARY);
  color: var(--FF-COLOR-SNOW);
  border-radius: var(--FF-BORDER-RADIUS);
  border: none;
  cursor: pointer;
}

.formError {
  color: var(--FF-COLOR-FAILURE);
  text-align: right;
  margin-right: 8%;
  padding-bottom: 5px;
  font-weight: bold;
}

.locationSelectionWidget {
  margin: 5px;
}
.addressWidget {
  font-weight: bold;
}

// Fix for non-styled Address Widget by default
:global .ff-address-widget {
  font-weight: bold;
  label {
    padding: 5px;
    display: block;
    padding-bottom: 0;
  }
  input {
    width: 90%;
    padding: 5px;
    margin: 5px;
    font-family: inherit;
    font-size: inherit;
  }
}
// Quick fix for wrongly sized delete button
:global .ff-delete-all {
  max-width: 15px !important;
}
